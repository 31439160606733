import {
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Email,
  EventOutlined,
  Inventory2Outlined,
  Language,
  Person,
  Phone,
  Wallet,
} from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import PasswordIcon from '@mui/icons-material/Password';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useClubExperienceContext
  from '../../hooks/clubExperience/useClubExperienceContext';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import SiteOnboardingButtons
  from '../../components/_Global/Site/SiteOnboardingButtons';
import SiteLanguageSwitcher
  from '../../components/_Global/Site/SiteLanguageSwitcher';
import ContainerFixed from '../../components/_Global/Containers/ContainerFixed';
import ProfileImageViewer
  from '../../components/_Global/Profile/ProfileImageViewer';
import DividerVertical from '../../components/_Global/Dividers/DividerVertical';
import IconSocialFacebook from '../../assets/social/IconSocialFacebook';
import IconSocialInsta from '../../assets/social/IconSocialInsta';
import SiteUserProfile from '../../components/_Global/Site/SiteUserProfile';
import LayoutClubHeaderAuth from './LayoutClubHeaderAuth';
import { useTranslate } from '@tolgee/react';
import LayoutClubMobileHeader from './LayoutClubMobileHeader';
import { fireGAEvent } from '../../utils/google-analytics';
import IconSocialTwitter from '../../assets/social/IconSocialTwitter';
import MobileAppBanner from './MobileAppBanner';
import useViewMobileBanner from '../../hooks/onboarding/useViewMobileBanner';
import { signUpEvents } from '../../utils/analyticsEvents';

function LayoutClubHeader() {

  const { pathname, search } = useLocation();
  const isMobile = useMediaQuery('(max-width:700px)');
  const { t } = useTranslate();
  const { clubId } = useParams();
  const isAuthenticated = useIsAuthenticated();

  const { clubDetails } = useClubExperienceContext();

  useEffect(() => {
    document.title = clubDetails?.name;
  }, [clubDetails]);

  const navItems = [
    {
      name: t('sideBar.link.textHome'),
      route: `/club/${clubId}`,
      icon: false,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textBooking'),
      route: `/club/${clubId}/bookings`,
      activeOnRoot: true,
      icon: DateRangeIcon,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textEvents'),
      route: `/club/${clubId}/events`,
      icon: EventOutlined,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.wallet'),
      route: `/club/${clubId}/wallet`,
      icon: Wallet,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.muClubs'),
      route: `/club/${clubId}/my-clubs`,
      icon: Wallet,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textSetting'),
      route: `/club/${clubId}/settings`,
      icon: SettingsIcon,
      disabled: false,
      canAccess: true,
      subMenuItems: [
        {
          icon: Person,
          name: t('settings.playerModule.profileName'),
          route: `/club/${clubId}/settings/profile`,
          canAccess: true,
        },
        {
          icon: PasswordIcon,
          name: t('settings.playerModule.passwordName'),
          route: `/club/${clubId}/settings/change-password`,
          canAccess: true,
        },
        {
          icon: Inventory2Outlined,
          name: t('settings.playerModule.packages'),
          route: `/club/${clubId}/settings/packages`,
          canAccess: true,
        },
      ],
    }];

  return (
    <Box component={'header'}>
      {
        isMobile && isAuthenticated &&
        <LayoutClubMobileHeader navItems={navItems} />
      }
      {
        isMobile && !isAuthenticated &&
        <LayoutClubHeaderTop isMobile={isMobile} />
      }
      {
        !isMobile &&
        <LayoutClubHeaderTop search={search} url={pathname} />
      }
      <LayoutClubHeaderBottom navItems={navItems} />
    </Box>
  );
}

function LayoutClubHeaderTop({isMobile}) {

  const theme = useTheme();
  const isAuthenticated = useIsAuthenticated();
  const { fetchClubPolicies } = useClubExperienceContext();
  const { viewMobileBanner, setViewMobileBanner } = useViewMobileBanner()

  return (
    <>
      {
        isMobile && viewMobileBanner &&
        <MobileAppBanner
          setView={setViewMobileBanner}
        />
      }
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <ContainerFixed>
          <Box sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
          }}>
            <Stack ml={'auto'} direction={'row'} spacing={1.5}
              alignItems={'center'}>
              <SiteLanguageSwitcher />
              <DividerVertical />
              <SiteOnboardingButtons
                size={'small'}
                variantSignIn={'text'}
                variantRegister={'text'}
                onRegisterSuccess={() => {
                  fireGAEvent('signup_success', 'Onboarding', 'Sign up');
                }}
                onRegisterFailure={()=>{
                  signUpEvents['signUpFailed']()
                }}
                onLoginSuccess={() => {
                  fetchClubPolicies();
                }}
              />
              {
                isAuthenticated &&
                // <SiteLogoutButton/>
                <SiteUserProfile
                  type={'club'}
                  onLogoutSuccess={() => {
                    fetchClubPolicies()
                  }}
                />
              }
            </Stack>
          </Box>
        </ContainerFixed>
      </Box>
    </>
  );
}

function LayoutClubHeaderBottom({ navItems }) {

  const { t } = useTranslate();
  const theme = useTheme();
  const { clubDetails } = useClubExperienceContext();
  const navigateTo = useNavigate();
  const { clubId } = useParams();
  const isAuthenticated = useIsAuthenticated();

  const address = clubDetails?.addresses[0];

  const isMobile = useMediaQuery('(max-width:700px)');

  console.log('header',theme.palette.primary.main)

  return (
    <Box>
      {/*Background*/}
      <Box
        sx={{
          height: '116px',
          background: `linear-gradient(91deg, ${theme.palette.secondary.main} 7.42%, ${theme.palette.primary.main} 91.23%);`,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box sx={{
          position: 'absolute',
          right: '250px',
          top: '50px',
        }}>
          <CircularGraphic />
        </Box>
      </Box>
      {/*End Background*/}

      <ContainerFixed>
        <Box sx={{ position: 'relative', zIndex: 99 }}>
          <Box
            sx={{
              position: 'absolute',
              width: '95%',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: '5px',
              boxShadow: `-1px -1px 30px 0px rgba(0,0,0,0.43)`,
              height: '20px',
              zIndex: 0,
              borderRadius: '20px',
            }}
          >
          </Box>
          <Box
            sx={{
              mt: !isMobile ? '-60px' : '-80px',
              padding: '20px',
              backgroundColor: '#fff',
              borderRadius: '18px',
              border: `1px solid ${theme.palette.divider}`,
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Stack
              direction={!isMobile ? 'row' : 'column'}
              width={'100%'}
              justifyContent={'space-between'}
              alignItems={!isMobile ? 'flex-end' : 'center'}
            >

              {/*Left*/}
              <Stack
                direction={!isMobile ? 'row' : 'column'}
                alignItems={'center'}
                spacing={1}
                mb={isMobile && 2}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigateTo(`/club/${clubId}`);
                  }}
                >
                  <ProfileImageViewer
                    size={'md'}
                    imgPath={clubDetails?.imageUrl}
                    placeholderStyle={'shield-icon'}
                  />
                </Box>

                <Stack
                  alignItems={isMobile && 'center'}
                  textAlign={isMobile && 'center'}
                >
                  <Typography
                    variant={'h5-bold'}
                    lineHeight={1.1}
                    mb={.5}
                    color={theme.palette.secondary.main}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => {
                      navigateTo(`/club/${clubId}`);
                    }}
                  >
                    {clubDetails?.name}
                  </Typography>
                  <Typography variant={'body2'} fontWeight={400}>
                    {`${address?.streetAddress} ${address?.houseNumber}, ${address?.postalCode} ${address?.city}, ${address?.country}`}
                  </Typography>
                </Stack>
              </Stack>

              {/*Right*/}
              <Stack alignItems={!isMobile ? 'flex-end' : 'center'}
                spacing={.5}>
                <SocialLinks clubDetails={clubDetails} />
                {
                  !isMobile &&
                  <Stack direction={'row'} spacing={1}>
                    <Link
                      color={'text.secondary'}
                      underline={'hover'}
                      fontSize={'14px'}
                      href={`tel:${clubDetails?.contact}`}
                    >
                      {clubDetails?.contact}
                    </Link>
                    <Link
                      color={'text.secondary'}
                      underline={'hover'}
                      fontSize={'14px'}
                      href={`mailto:${clubDetails?.email}`}
                    >
                      {clubDetails?.email}
                    </Link>
                  </Stack>
                }
              </Stack>
            </Stack>
            {isAuthenticated && !isMobile &&
              <LayoutClubHeaderAuth navItems={navItems} />
            }
          </Box>

        </Box>
      </ContainerFixed>
    </Box>
  );
}

function SocialLinks({ clubDetails }) {

  const isMobile = useMediaQuery('(max-width:700px)');

  return <Stack direction={'row'} alignItems={'center'} spacing={isMobile && 2}>
    {
      (isMobile && clubDetails?.contact) &&
      <SocialLinksItem
        href={`tel:${clubDetails?.contact}`}
        Icon={<Phone />}
      />
    }
    {
      (isMobile && clubDetails?.email) &&
      <SocialLinksItem
        href={`mailto:${clubDetails?.email}`}
        Icon={<Email />}
      />
    }
    {
      clubDetails?.website &&
      <SocialLinksItem
        href={clubDetails?.website}
        Icon={<Language color={'action'} />}
      />
    }
    {
      clubDetails?.instagram &&
      <SocialLinksItem
        href={clubDetails?.instagram}
        Icon={<IconSocialInsta
          width={'24px'}
          height={'24px'}
        />}
      />
    }
    {
      clubDetails?.facebook &&
      <SocialLinksItem
        href={clubDetails?.facebook}
        Icon={<IconSocialFacebook
          width={'24px'}
          height={'24px'}
        />}
      />
    }
    {
      clubDetails?.twitter &&
      <SocialLinksItem
        href={clubDetails?.twitter}
        Icon={<IconSocialTwitter
          width={'18px'}
          height={'18px'}
        />}
      />
    }
  </Stack>;
}

function SocialLinksItem({ href, Icon }) {
  return <Link href={href} target={'_blank'}>
    <IconButton size={'small'}>
      {Icon}
    </IconButton>
  </Link>;
}

function CircularGraphic() {

  const theme = useTheme();

  return (<Box position={'relative'}>
    <Box
      position={'absolute'}
      zIndex={3}
      width={'226px'}
      height={'226px'}
      left={'50%'}
      top={'50%'}
      sx={{
        backgroundColor: theme.palette.secondary.main,
        transform: 'translate(-50%,-50%)',
        borderRadius: '100%',
        mixBlendMode: 'hard-light',
        opacity: .15,
      }}
    ></Box>
    <Box
      position={'absolute'}
      zIndex={2}
      width={'396px'}
      height={'396px'}
      left={'50%'}
      top={'50%'}
      sx={{
        backgroundColor: theme.palette.secondary.light,
        mixBlendMode: 'hard-light',
        opacity: .2,
        transform: 'translate(-50%,-50%)',
        borderRadius: '100%',
      }}
    ></Box>
    <Box
      position={'absolute'}
      zIndex={1}
      width={'532px'}
      height={'532px'}
      left={'50%'}
      top={'50%'}
      sx={{
        backgroundColor: 'rgba(255,255,255,.2)',
        transform: 'translate(-50%,-50%)',
        borderRadius: '100%',
      }}
    ></Box>
  </Box>);
}

export default LayoutClubHeader;
